.capabilities-container {
      background-color: #1a1b1e;
      flex-grow: 0;
      height: auto;
      padding: 1px 0 18px;
      width: 100%;
  }
.capabilities {
      color: #fff;
      font-family: Outfit;
      font-size: 19px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      height: 24px;
      letter-spacing: 0.38px;
      line-height: normal;
      margin: 77px 72px 34px 78px;
      text-align: left;
      width: 104px;
  }
.continuous-teams-global-coverage {
      color: #fff;
      font-family: Outfit;
      font-size: 90px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      height: auto;
      letter-spacing: normal;
      line-height: 0.99;
      margin: 34px 37px 26px 77px;
      text-align: left;
      width: 65%;
  }
.engineering {
      color: #fff;
      font-family: Outfit;
      font-size: 35px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      height: 90px;
      letter-spacing: normal;
      line-height: 2.18;
      margin: 26px 183.5px 9px 77px;
      text-align: left;
      width: 35%;
  }
.strategy-innovation {
      color: #fff;
      font-family: Outfit;
      font-size: 35px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      height: 78px;
      letter-spacing: normal;
      line-height: 2.18;
      margin: 26px -38px 7px -8.5px;
      text-align: left;
      width: 101%;
  }
.experience-design {
      color: #fff;
      font-family: Outfit;
      font-size: 35px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      height: 90px;
      letter-spacing: normal;
      line-height: 2.18;
      margin: 26px 34px 9px 36.5px;
      text-align: left;
      width: 100%;
  }
.engineering-text {
      color: #fff;
      font-family: Outfit;
      font-size: 19px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 300;
      height: 425px;
      letter-spacing: normal;
      line-height: normal;
      margin: 9px 88.5px 5px -9px;
      text-align: left;
      width: 50%;
  }
.strategy-innovation-text {
      color: #fff;
      font-family: Outfit;
      font-size: 19px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 300;
      height: 210px;
      letter-spacing: normal;
      line-height: normal;
      margin: 9px 1px 25px -8.5px;
      text-align: left;
      width: 75%;
  }
.experience-design-text {
      color: #fff;
      font-family: Outfit;
      font-size: 19px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 300;
      height: 315px;
      letter-spacing: normal;
      line-height: normal;
      margin: 9px 8px 132px -5.5px;
      text-align: left;
      width: 100%;
  }
.ellipse-1 {
      background-color: #d9d9d9;
      border: 2px solid #d9d9d9;
      border-radius: 50%;
      display: inline-block;
      flex-grow: 0;
      height: 16px;
      margin-left: 99%;
      width: 16px;
  }
.ellipse-2 {
      border: 2px solid #d9d9d9;
      border-radius: 50%;
      display: inline-block;
      flex-grow: 0;
      height: 16px;
      width: 16px;
  }
.bullet-text-style {
      font-family: Outfit;
      font-size: 17px;
      height: 430px;
      margin: 15px -27px 17px 11.5px;
      width: 398px;
  }
.grid-container {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr;
      padding: 20px;
  }
/* for tablets 1 columns */
@media (min-width: 576px) {
    .grid-container {
          grid-template-columns: repeat(1, 1fr);
      }
  }
/* for desktop 2 columns */
@media (min-width: 992px) {
    .grid-container {
          grid-template-columns: repeat(2, 1fr);
      }
  }
/* for desktop 3 columns */
@media (min-width: 1200px) {
    .grid-container {
          grid-template-columns: repeat(3, 1fr);
      }
  }
.grid-item {
      border-radius: 5px;
      color: #fff;
      font-family: Outfit;
      font-size: 35px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.38px;
      line-height: normal;
      margin: 5px -148px 24px 55px;
      padding: 5px;
      text-align: center;
      text-align: left;
  }
.vertical_line {
      border-left: 2px solid #3f3a3a;
      height: 450px;
      margin: 20px auto;
      transition: height 0.3s ease;
  }
