.left-panel-div {
  width: 27%;
  height: auto;
  float: left;
  background-color: #f5f5f5;
  max-height: 750px;
  overflow-y: scroll;
}
 
.right-panel-main-div {
  width: 75%;
  height: 600px;
  float: left;
}
 
.right-panel-top-div {
  width: 100%;
  height: 50px;
  float: left;
  margin-top: 20px;
  margin-left: 3%;
}
 
.btn-bg-color {
  background-color: #f5f5f5;
  border-radius: 20px;
  margin: 1px 5px 4px -140px;
}
 
.right-panel-middle-div {
  width: 100%;
  height: 580px;
  float: left;
  margin-top: 10px;
  margin-left: 1%;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}
 
.right-panel-bottom-div {
  width: 100%;
  height: 175px;
  float: left;
  margin-top: 10px;
  margin-left: 5%;
  background-color: #fff;
}
 
.middle-card-alignment {
  margin-top: 2%;
}
 
.bottom-card-alignment {
  margin-top: 10%;
}
 
.search-insights-clear-btn {
  margin-top: -135px;
  margin-left: 211px;
}
 
.industries-clear-btn {
  margin-top: -515px;
  margin-left: 211px;
}
 
.services-clear-btn {
  margin-top: -142px;
  margin-left: 211px;
}
 
.arrow-right {
  width: 28px;
  height: 28.6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7.3px;
  margin: 96px 24px 5.4px 93%;
  padding: 7.3px;
  object-fit: contain;
  border-radius: 50.9px;
  background-color: #1a1b1e;
  margin-left: 102%;
}
 
.launch-academy {
  width: 159px;
  height: 120px;
  margin: 17px 172px 20.6px 13px;
  font-family: Outfit;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: #1a1b1e;
}
 
.be-Launchified {
  width: 702px;
  height: 160px;
  flex-grow: 0;
  padding: 0 87.8px 0 0;
  font-family: Outfit;
  font-size: 80px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.99;
  letter-spacing: normal;
  text-align: left;
  color: #1a1b1e;
  margin-top: -9%;
  margin-left: 1%;
}
 
.courses-found {
  width: 223px;
  height: 25px;
  margin: 108.4px 707px 29px 26px;
  font-family: AvenirNext;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c1b1f;
}
 
.generative-AI {
  width: 207px;
  height: 19px;
  flex-grow: 0;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c1b1f;
}
 
.certificate {
  width: 95px;
  height: 16px;
  flex-grow: 0;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c1b1f;
}
 
.gen-AI-101-certification {
  height: 19px;
  align-self: stretch;
  flex-grow: 0;
  font-family: Outfit;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: left;
  color: #1a1b1e;
}
 
.knowledge-check-gen-AI {
  height: 80px;
  align-self: stretch;
  flex-grow: 0;
  font-family: Outfit;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1c1b1f;
}
 
input {
  padding: 10px;
  width: 100%;
  height: 60px;
  max-width: 85%;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
 
.card {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  font-family: Outfit;
}
 
.card h3 {
  margin: 0;
  font-size: 18px;
}
 
.card p {
  font-size: 12px;
  color: #555;
}
 
.card-bottom-row-align {
  margin-top: 40px;
 
}
 
.crad-star-align {
  margin: 2px 35px 23px 180px;
}
 
.intended-audience-everyone {
  width: 124px;
  height: 38px;
  margin: 6.5px 36px 7px -1px;
  font-family: Outfit;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1a1b1e;
}
 
.intended-audience-everyone .text-style-1 {
  font-weight: bold;
}
 
.duration-150-minutes {
  width: 82px;
  height: 60px;
  margin: 6.5px -11px -15px -24px;
  font-family: Outfit;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1a1b1e;
}
 
.duration-150-minutes .text-style-1 {
  font-weight: normal;
}
 
.more-than-215-learners {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 378px;
  height: 22px;
  align-self: stretch;
  flex-grow: 0;
  margin: 6.5px -3px 13px 1px;
  font-family: Outfit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1a1b1e;
}
 
.more-than-215-learners .text-style-1 {
  font-weight: bold;
}
 
.enroll {
  border-radius: 2rem;
  color: #fbfbff;
  font-family: Outfit;
  font-size: 19px;
  font-weight: 500;
  margin: 3.5px 7px 5px 5px;
  max-width: 350px;
  width: 60%;
}
