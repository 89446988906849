.textN {
    color: #1a1b1e;
    font-family: Outfit;
    font-size: 50px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    height: 98px;
    left: 54px;
    letter-spacing: normal;
    line-height: 1.08;
    margin: 42px 77px 18px 28px;
    position: relative;
    top: 40px;
    width: 941px;
  }
.paraN {
    color: #1a1b1e;
    font-family: "Outfit";
    font-size: 30px;
    margin-bottom: 25px;
    margin-left: 75px;
  }
