.resources-case-study {
    color: #f5f5f5;
    font-family: Outfit;
    font-size: 19px;
    margin: 0;
    margin-bottom: 50px;
    margin-top: 50px;
  }
.resource-study-enrol {
    color: #d9d9d9;
    font-family: Outfit;
    font-size: 68px;
    font-weight: bold;
    line-height: 1;
    margin: 0;
  }
.resource-study-paraS {
    color: #d9d9d9;
    font-family: Outfit;
    font-size: 17px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
.right-column {
    position: relative;
    z-index: 2;
  }
.right-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 70px;
    position: relative;
    z-index: 2;
  }
.btnExplore {
    border-radius: 5rem;
    color: #1a1b1e;
    font-family: Outfit;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 50px;
    margin-top: 20px;
    width: 250px;
  }
.resources-case-study-launch-data {
    align-items: center;
    background: #1a1b1e;
    display: flex;
    height: auto;
    padding: 50px;
  }
.resources-case-study-launch-data {
    color: #ffffff;
    padding: 10px;
  }
/* Responsive Styles */
@media (max-width: 768px) {
  .resources-case-study {
      font-size: 16px;
      margin: 20px;
    }
  .resource-study-enrol {
      font-size: 40px;
      line-height: 1.2;
      margin: 20px 10px;
    }
  .btnExplore {
      font-size: 18px;
      padding: 10px;
      width: 100%;
    }
  }
@media (max-width: 576px) {
  .resources-case-study {
      font-size: 14px;
    }
  .resource-study-enrol {
      font-size: 30px;
      line-height: 1.1;
    }
  }
