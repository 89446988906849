.navbar {
  align-items: center;
  background: #1a1b1e;
  display: flex;
  font-size: 1.2rem;
  font-weight: normal;
  height: 72px;
  justify-content: center;
  margin-bottom: 0;
  position: relative;
  top: 0;
  z-index: 10;
}
.navbar-res {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.nav-item {
  color: white;
  font-weight: normal;
}
.nav-link {
  color: #f5f5f5;
  padding: 10px;
  position: relative;
  text-decoration: none;
}
.nav-link:hover {
  color: #f6f6f2;
}
.navbar-container {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  max-width: 1500px;
}
.navbar-logo {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  justify-self: start;
  margin-top: 13px;
  text-decoration: none;
}
.fa-typo3 {
  font-size: 1.8rem;
  margin-left: 0.5rem;
}
.nav-menu {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(5, auto);
  justify-content: center;
  list-style: none;
  position: sticky;
  text-align: center;
  width: 60vw;
}
.nav-item {
  height: 80px;
}
.nav-links {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
.fa-bars {
  color: #fff;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}
.arrow .arrowicon {
  left: 10%;
}
.vector_h {
  background-color: rgba(73, 74, 81, 0.5);
  height: 0.01rem;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 100vw;
  z-index: 1;
}
.user_data {
  font-size: 1rem;
}
.dropdown-card {
  margin: 0;
  padding: 0;
}
.dropdown-header-black {
  background-color: #1a1a1a;
  display: flex;
  width: auto;
  height: 6rem;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
}
.profile-pic-container {
  position: absolute;
  top: 40%;
  left: 20px;
  z-index: 1;
}
.profile-pic-header {
  width: 70px;
  margin-top: 10%;
  margin-left: 25%;
  border-radius: 50%;
  object-fit: cover;
}
.header-logo {
  margin-left: auto;
  width: 70px;
  height: 70px;
}
.user-info-section {
  padding: 20px;
  position: relative;
  background-color: white;
}
.user-name-header {
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
  white-space: nowrap;
  font-family: "Outfit";
  margin-top: 3%;
}
.info-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-role-section {
  text-align: left;
  white-space: nowrap;
}
.user-role-title {
  font-size: 0.8rem;
  color: #666666;
  margin-bottom: 1%;
  white-space: nowrap;
  font-family: "Outfit";
}
.user-role-subtitle {
  font-size: 0.8rem;
  color: #999999;
}
.vertical-vector {
  width: 1px;
  height: 60px;
  background-color: #e7e0e0;
}
.performance-section {
  text-align: left;
  white-space: nowrap;
}
.performance-title {
  font-size: 0.8rem;
  color: #666666;
  margin-bottom: 0;
  font-family: "Outfit";
}
.performance-value {
  font-size: 0.8rem;
  font-weight: bold;
  color: #333333;
  font-family: "Outfit";
}
.score-section {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
}
.score-big {
  font-size: 2rem;
  font-weight: bold;
  color: red;
  margin-left: 10%;
}
.score-divider {
  font-size: 1.5rem;
  margin: 0 5px;
}
.score-small {
  font-size: 1.2rem;
  color: #333333;
}
.horizontal-vector {
  height: 1px;
  width: 100%;
  background-color: #e7e0e08a;
  margin-top: 0;
}
.dropdown-card {
  background-color: white;
  border: 1px solid #3e3939;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 24rem;
  height: 31rem;
  font-family: "Outfit";
}
 
.dropdown-content {
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
}
.badges-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.badges-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 3%;
}
.badges-container img {
  height: 60px;
  object-fit: contain;
  margin-bottom: 1px;
  margin-left: 1%;
}
.menu-options {
  margin-top: 1%;
  font-weight: 500;
  font-family: "outfit";
}
.menu-options a {
  color: #000103;
  text-decoration: none;
  font-family: "outfit";
}
.menu-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #000103;
  cursor: pointer;
  margin-bottom: 10px;
}
.menu-item i {
  font-size: 20px;
  margin-right: 10px;
}
 
@media screen and (max-width: 768px) {
  .dropdown-card {
    right: 5%;
    width: 90%;
  }
  .header-logo {
    height: 40px;
    width: 40px;
  }
  .badges-container img {
    height: 50px;
    width: 50px;
  }
  .profile-pic-header {
    width: 60px;
    height: 60px;
  }
  .profile-pic-container {
    top: 50%;
  }
}
 
@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    height: 80vh;
    margin-right: 200px;
    opacity: 1;
    position: absolute;
    top: 80px;
    transition: all 0.5s ease;
    width: 100%;
  }
  .nav-menu.active {
    background: #f9f9f9;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    display: table;
    padding: 2rem;
    text-align: center;
    width: 100%;
  }
  .menu-icon {
    cursor: pointer;
    display: block;
    font-size: 1.8rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-100%, 60%);
  }
  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
  .nav-links-mobile {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #faf8f8;
    display: block;
    font-size: 1.5rem;
    margin: 2rem auto;
    padding: 14px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease-out;
    width: 80%;
  }
}
.launch-word {
  margin-bottom: 20%;
  margin-left: 3.5%;
}
.launch-logo {
  height: 46px;
  margin: 0px 46px 0px 15px;
  object-fit: contain;
  width: 133px;
}
.home_icon {
  position: relative;
  right: 8px;
  width: 47px;
}
.launchified_data {
  color: #d0d0d0;
  font-family: "Outfit";
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: normal;
  margin-left: 4px;
  margin-right: 10px;
  position: relative;
  text-align: left;
  text-decoration: none;
  top: 21px;
  font-size: 1rem;
}
.user_data {
  color: #fff;
  font-family: "Outfit", sans-serif;
  left: 120px;
  position: relative;
  top: 8px;
}
.arrow {
  left: 110px;
  position: relative;
  top: 8px;
}
 
.Vector-2 {
  background-color: rgba(72, 74, 82, 0.5);
  height: 71px;
  left: -40px;
  position: relative;
  width: 1px;
}
 