.grow-container {
    padding: 4rem 1rem;
    padding-left: 2rem;
   }
   .why-launchified2 {
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 1rem;
    font-family: "Outfit";
   }
   .main-heading {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 1.5rem;
    font-family: "Outfit";
   }
   .main-heading span {
    display: block;
    font-size: 8rem;
    color: #000000;
    margin-top: 1%;
    font-family: "Outfit";
   }
   .description {
    font-size: 1.2rem;
    color: #000000;
    margin-bottom: 3%;
    margin-top: 4%;
    font-family: "Outfit";
   }
   .explore-button {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
    border-radius: 5rem;
    font-family: "Outfit";
   }