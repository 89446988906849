.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 100px;
  }
.main-content {
    margin-left: 8%;
    margin-top: 152px;
  }
.arrow-right {
    align-items: center;
    background-color: #1a1b1e;
    border-radius: 50.9px;
    display: flex;
    flex-direction: row;
    gap: 7.3px;
    height: 28.6px;
    justify-content: center;
    margin: 50px 24px 5.4px 1335px;
    object-fit: contain;
    padding: 7.3px;
    width: 28px;
  }
.launchified {
    color: #1a1b1e;
    font-family: Outfit;
    font-size: 19px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.38px;
    line-height: normal;
    
    text-align: left;
    width: 102px;
  }
.resources {
    color: #1a1b1e;
    font-family: Outfit;
    font-size: 90px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    height: 90px;
    letter-spacing: normal;
    line-height: 0.99;
    margin: 21px 219px 21px 0;
    text-align: left;
    width: 419px;
  }
.Were-your-one-stop-text-resource {
    color: #1a1b1e;
    font-family: Outfit;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    height: 42px;
    letter-spacing: normal;
    line-height: normal;
    margin: 21px 38px -55px 5px;
    text-align: left;
    width: 44%;
  }
