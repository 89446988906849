.launchDetails {
  background: #1a1b1e;
  display: flex;
  height: 543px;
  width: 100%;
}
.line {
  background-color: #fff;
  background-color: rgba(72, 74, 82, 0.5);
  display: flex;
  flex-grow: 0;
  height: 150px;
  border-top: 20%;

  width: 1px;
  margin-top: 210%;
  margin-left: 43%;
}
.launchified-academy {
  color: #fff;
  font-family: Outfit;
  font-size: 19px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 24px;
  letter-spacing: 0.38px;
  line-height: normal;
  margin: 133.5px 74px 21px 78px;
  text-align: left;
  width: 102px;
}
.launchified-academy1 {
  color: black;
  font-family: Outfit;
  font-size: 19px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 24px;
  letter-spacing: 0.38px;
  line-height: normal;
  margin: 73.5px 74px 21px 78px;
  text-align: left;
  width: 102px;
}
.Practice-Leadership {
  color: #fff;
  flex: 1 1;
  font-family: Outfit;
  font-size: 90px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 0.99;
  margin-top: 182px;
  margin-left: -152px;
  top: -47px;
}
.Vector-Launch {
  background-color: rgba(72, 74, 82, 0.5);
  bottom: 200px;
  height: 71px;
  left: 56px;
  position: relative;
  width: 1px;
}
.The-Launch-Experience {
  bottom: 56px;
  color: #fff;
  flex: 1 1;
  font-family: Outfit;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  height: 125px;
  letter-spacing: normal;
  line-height: normal;

  text-align: left;
  width: 550px;
  margin-top: 40%;
  margin-left: -3%;
}
.Soares-Tammy {
  color: #1a1b1e;
  display: flow;
  font-family: Outfit;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  height: 56px;
  letter-spacing: normal;
  line-height: 1;
  margin: 0 204px 9px 0;
  text-align: left;
  width: 106px;
}
.PRODUCT-LAUNCH-PRESIDENT {
  color: #1a1b1e;
  display: flow;
  font-family: Outfit;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 23px;
  letter-spacing: normal;
  line-height: 1.77;
  margin: 9px 122px 0 1px;
  text-align: left;
  width: 226px;
}
.LinkedInImg {
  bottom: 43px;
  position: relative;
  right: -233px;
}
.leadersContainer {
  bottom: 100px;
  position: relative;
  right: -33px;
}
.Soares-Tammy {
  color: #1a1b1e;
  font-family: Outfit;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  height: 56px;
  letter-spacing: normal;
  line-height: 1;
  margin: 0 182px 9px 0;
  text-align: left;
  width: 128px;
}
.Vector-10 {
  background-color: rgba(217, 217, 217, 0.6);
  height: 1px;

  width: 1213.5px;
  margin-top: -6%;

  
}
.line2{
  background-color: #fff;
  background-color: rgba(72, 74, 82, 0.5);

 
  display: flex;

  flex-grow: 0;
  height: 152px;
  border-top: 20%;
  width: 1px;

  margin-left: -27%;
  margin-top: 30%;
}
.launchDetails2 {
  background: #fefefe;
  display: flex;
  height: 543px;
  top: 16px;
  width: 101%;
}
.leadersContainer2 {
  margin-bottom: 20px;
}
.leadersContainer3 {
  bottom: -111px;
  position: relative;
  right: -33px;
}
.Exp-Leadership {
  color: black;
  flex: 1 1;
  font-family: Outfit;
  font-size: 90px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;



  

  width: 521px;


  flex: 1 1;
  font-family: Outfit;
  font-size: 90px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;

  letter-spacing: normal;
  line-height: 0.99;

  margin-top: 121px;

  margin-left: -152px;

  top: -47px;
}
.The-Launch-ExperienceP {
  bottom: 71px;
  color: black;
  flex: 1 1;
  font-family: Outfit;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  height: 125px;
  letter-spacing: normal;
  line-height: normal;
  margin: 58px 114px 131px 90.5px;
  /* margin: -7px 157px 131px 39.5px; */
  text-align: left;
  width: 550px;
  margin-top: -27%;
  margin-left: 43%;
}
@media (min-width: 576px) and (max-width: 777px) {
  .The-Launch-Experience {
    bottom: 77px;
    width: 508px;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .The-Launch-Experience {
    bottom: 77px;
    width: 900px;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .The-Launch-ExperienceP {
    bottom: 77px;
    width: 900px;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .The-Launch-ExperienceP {
    width: 996px;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .The-Launch-Experience {
    margin-left: 10px;
    width: 996px;
  }
}
.exp-des-cards{
  margin-top:-13%;
}
