.technologies {
    color: #1a1b1e;
    font-family: Outfit;
    font-size: 19px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.38px;
    line-height: normal;
    margin: 0 11px 16px 1px;
    margin-top: 40px;
    text-align: left;
    width: 40%;
  }
.agile-ways-of-working {
    color: #1a1b1e;
    font-family: Outfit;
    font-size: 90px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    height: auto;
    letter-spacing: normal;
    line-height: 0.99;
    margin: 34px 0 45px;
    text-align: left;
    width: 45.5%;
  }
.agile-quality-engineering-text {
    color: #1a1b1e;
    font-family: Outfit;
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    height: auto;
    letter-spacing: normal;
    line-height: normal;
    margin: 45px 130px 65px 2px;
    text-align: left;
    width: 35.5%;
  }
img.dispaly-logo {
    height: 100px;
    margin: 17px 25px 51px 20px;
    object-fit: contain;
    width: 100%;
  }
.image {
    height: 354px;
    margin: 40px 125px 76px 86px;
    width: 474px;
  }
.agile-way-of-working-image {
    margin-left: 53%;
    margin-top: -35%;
    width: 100%;
  }
.agile-way-of-working-image img {
    height: auto;
    max-width: 35%;
  }
.agile-way-bottom-verticle-line {
    background-color: rgba(217, 217, 217, 0.6);
    height: 1px;
    margin-bottom: 130px;
    margin-top: -30px;
    width: 100%;
  }
