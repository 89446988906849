.footer {
  background-color: #1a1b1e;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
  padding: 0;
  padding: 4% 4%;
  position: relative;
}
.main-container {
  margin: 0 auto;
  width: 100%;
}
nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5%;
}
nav ul {
  display: flex;
  height: 25px;
  list-style: none;
  margin-left: -37px;
}
nav ul li {
  margin: 0 12px;
}
nav ul li a {
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}
.social-icons {
  bottom: 37%;
  position: absolute;
  right: 4%;
}
.social-icons a {
  display: inline-block;
  margin: 0 1px;
  text-decoration: none;
  transition: all 100ms;
}
.social-icons a:hover {
  transform: scale(1.2);
}
.verticle-line {
  background-color: rgb(58, 52, 55);
  height: 1px;
  margin-bottom: 20px;
  margin-top: 40px;
  width: 100%;
}
