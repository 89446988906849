.resoursesContainer1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -122px;
    margin-top: 50px;
  }
.name {
    color: #000;
    font-family: Outfit;
    font-size: 25px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    letter-spacing: normal;
    line-height: normal;
    margin: 20px 15.9px -35px 47.1px;
    text-align: left;
    width: 240px;
  }
.arrowright {
    color: #1a1b1e;
    display: flow;
    font-family: Outfit;
    font-size: 19px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 34px;
    letter-spacing: normal;
    line-height: 1.77;
    margin: 9px 22px 13px 5px;
    margin-top: -30px;
    text-align: right;
    width: 95%;
  }
.resoursesContainer2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -122px;
    margin-top: 50px;
  }
.resourcecard {
    border-radius: 0px;
    cursor: pointer;
    transition: transform 0.1s;
    transition: all 0.3 ease;
  }
.resourcecard:hover {
    border-radius: 1px;
    transform: scale(1.05);
  }
