.main-content {
      margin-bottom: 80px;
      margin-left: 8%;
      margin-top: 20px;
  }
.imageSp{
      left:-10%;
      position:relative;
  }
.arrow-right {
      align-items: center;
      background-color: #1a1b1e;
      border-radius: 50.9px;
      display: flex;
      flex-direction: row;
      gap: 7.3px;
      height: 28.6px;
      justify-content: center;
      margin: 9% 2% 0.45% 95%;
      object-fit: contain;
      padding: 7.3px;
      width: 28px;
  }
.service-spectrum {
      color: #1a1b1e;
      font-family: Outfit;
      font-size: 19px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      height: 24px;
      letter-spacing: 0.38px;
      line-height: normal;
      margin: 25px 680px 21px 1px;
      text-align: left;
      width: 153px;
  }
.outshine-the-competition-text {
      color: #1a1b1e;
      font-family: Outfit;
      font-size: 74px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      height: auto;
      letter-spacing: normal;
      line-height: 0.99;
      text-align: left;
      width: 58%;
  }
.main-cap-div {
      background-color: white;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1300px;
      padding: 5px;
  }
.col1, .col2 {
      background-color: white;
      max-width: 410px;
      padding: 7px;
  }
.were-your-one-stop-digital-text {
      color: #1a1b1e;
      font-family: Outfit;
      font-size: 17px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 300;
      height: auto;
      letter-spacing: normal;
      line-height: normal;
      margin: 21px 237px 0 2px;
      text-align: left;
      width: 38%;
  }