.carousel-container {
    background-color: #1a1b1e;
    min-height: 100vh;
    padding: 20px 0;
  }
.media-video {
    border: 2px solid;
    border-radius: inherit;
    border-radius: 15px;
    height: auto;
    margin-right: 20%;
    margin-top: 10%;
    object-fit: contain;
    width: 107%;
  }
.launch-aca {
    color: #ffcc2d;
    font-family: "Outfit", sans-serif;
    font-size: calc(1.5rem + 0.3vw);
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-left: 15%;
    margin-top: 15%;
  }
.launch-bold {
    color: #fff;
    font-family: "Outfit", sans-serif;
    font-size: calc(4.5rem + 0.5vw);
    font-size: 50px;
    font-weight: bold;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
    margin-left: 15%;
    white-space: nowrap;
  }
.bold-css {
    font-family: "Outfit", sans-serif;
    font-size: calc(1.5rem + 0.3vw);
    font-size: 178px;
    font-weight: 900;
    margin-left: -2%;
    margin-top: -5%;
  }
.video-para {
    color: rgba(217, 217, 217, 0.6);
    font-family: "Outfit", sans-serif;
    font-size: 27px;
    margin-left: 12%;
    margin-top: -10%;
    padding: 2%;
    text-align: left;
  }
.llaunch-academy {
    color: #ffcc2d;
    font-family: "Outfit", sans-serif;
    font-size: calc(1.5rem + 0.3vw);
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-left: 20%;
    margin-top: 15%;
  }
.learn-earn {
    color: #fff;
    font-family: "Outfit", sans-serif;
    font-size: calc(4.5rem + 0.5vw);
    font-size: 150px;
    font-weight: bold;
    margin-left: 19%;
    white-space: nowrap;
  }
.earn-css {
    color: #fff;
    font-family: "Outfit", sans-serif;
    font-size: calc(4.5rem + 0.5vw);
    font-size: 150px;
    font-weight: bold;
    margin-left: 1%;
    margin-top: -12%;
    white-space: nowrap;
  }
.belaunchified {
    color: #fff;
    font-family: "Outfit", sans-serif;
    font-size: calc(4.5rem + 0.5vw);
    font-size: 39px;
    font-weight: bold;
    margin-left: 21%;
    margin-top: -6%;
    white-space: nowrap;
  }
.image-container {
    align-items: flex-start;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-top: 14%;
    padding: 2%;
    transform: scale(1.3);
  }
.image-pillar {
    margin-right: 24%;
    width: 38%;
  }
.carousel-indicators [data-bs-target] {
    background-color: #ffff;
    background-color: #ffff;
    border: 2px solid transparent;
    border-radius: 50%;
    height: 15px;
    margin: 5px;
    width: 15px;

transition:
    background-color 0.3s,
    transform 0.3s;
  }
.carousel-indicators .active {
    background-color: #3162e8;
    transform: scale(1.2);
  }
.paraMission {
    background-color: #1a1b1e;
    color: rgba(217, 217, 217, 0.6);
    font-family: "Outfit", sans-serif;
    font-size: 18px;
    margin-left: 7%;
    margin-top: -4%;
    padding: 0 0 18px;
    padding: 2%;
    text-align: left;
    transition: all 0.1s ease;
    width: 496px;
  }
.carousel-control-prev,
.carousel-control-next {
    top: 50%;
    transform: translateY(-80%);
    width: 2rem;
    height:2rem;
    background-color: #525255;
    padding: 10px;
    
  }

  .carousel-control-prev,
.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  width: auto;
}

.carousel-control-prev {
    left: -10px;
  }
.carousel-control-next {
    right: -10px;
  }
  .carousel-control-prev.disabled{
    opacity: 0.5;
    pointer-events:none;
  }
  .carousel-control-prev:not(.disabled),
  .carousel-control-next{
    opacity:1;
    pointer-events: auto;
  }
  .carousel-control-prev-icon, .carousel-control-next-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }