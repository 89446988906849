.how-section{
 position: relative;
 overflow: hidden;
}
.manImage {
  position: absolute;
  top: 30%;
  right: 0;
  width: 60%;
  height: 70%;
  object-fit: cover;
  z-index: 1;
  opacity: 0.9;
 }
.how-to-be-launchified {
  color: #f5f5f5;
  font-family: Outfit;
  font-size: 19px;
  margin: 0;
  margin-bottom: 50px;
  margin-top: 50px; 
}
.enrol {
  color: #d9d9d9;
  font-family: Outfit;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
}
.Launchi{
  color: #d9d9d9;
  font-family: Outfit;
  font-size: 9rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
}
.paraL {
  color: #d9d9d9;
  font-family: Outfit;
  font-size: 19px;
  margin-top: 30px;
}
.right-column {
  position: relative; 
  z-index: 2; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left; 
  padding-left: 50px; 
  color: #ffffff; 
  width: 60%;
} 

.btnExplore {
  border-radius: 5rem;
  color: #1a1b1e;
  font-family: Outfit;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  margin-top: 20px;
  width: 250px;
}
.how-to-be-launch-data {
  align-items: center;
  background: #1a1b1e;
  display: flex;
  height: auto;
  padding: 50px;
  position: relative;
  display: flex; 
  justify-content: space-between;  
}
.launch-details {
  border-radius: 18px;
  margin: 20px 0;
  padding: 40px;
  background-color: #fcfcfc;
  border-radius: 18px 18px 0 0;
  margin: auto;
  margin-top: 23px;
  max-width: 1960px;
  
}

.at-launch {
  color: #1a1b1e;
  font-family: Outfit;
  font-size: 19px;
  margin: 20px 0;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #1a1b1e;
  font-family: Outfit;
  font-size: 19px;
  margin: auto;
  max-width: 60%;
  padding: 20px;
  text-align: center;
} 
@media (max-width: 1100px) {
 .Launchi{
  font-size: 8rem;
 }
}

@media (max-width: 870px) {
  .Launchi{
    font-size: 6rem;
  }
}

@media (max-width: 670px) {
  .Launchi{
    font-size: 5rem;
  }
}
