.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.launch-course {
  color: #1a1b1e;
  font-family: Outfit;
  font-size: 36px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.38px;
  text-align: left;
}
.completed-courses {
  color: #1a1b1e;
  font-family: Outfit;
  font-size: 102px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  height: 59px;
  letter-spacing: normal;
  line-height: 0.99;
  margin-top: 4%;
  text-align: left;
  width: 1144px;
  font-size: calc(4rem + 1vw);
  font-weight: 700;
  font-family: Outfit;
  margin-left: -1%;
    margin-top: 1%;
}

.courseTitle {
  color: #1a1b1e;
  font-family: Outfit;
  font-size: 25px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  height: 44px;
  letter-spacing: normal;
  line-height: 1;
  text-align: left;
  width: 174px;
  
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.arrow-course {
  width: 28px;
  height: 28.6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7.3px;
  margin: -26px 0 5.4px 1093px;
  padding: 7.3px;
  border-radius: 50.9px;
  background-color: #1a1b1e;
  margin-top: 1%;

}
.courseTitle {
  color: #1a1b1e;
  font-family: Outfit;
  font-size: 25px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  height: 44px;
  letter-spacing: normal;
  line-height: 1;
  text-align: left;
  width: 174px;
}

.launch-course {
  font-size: calc(0.8rem + 0.5vw);
  font-weight: 600;
  font-family: Outfit;
  margin-bottom: 1rem;
}

.completedCourses-container{
  margin-top: 4%;
}

.text-start {
  text-align: start;
}

@media (max-width: 576px) {
  .launch-course {
    font-size: 1rem;
  }
  .completed-courses {
    font-size: 1.5rem;
  }
}
